import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Card as OriginalCard } from "../common/Card";
import StyledButton from "../common/StyledButton";
import StyledInput from "../common/StyledInput";
import Typography from "../common/Typography";
import FlexRowWrapper from "../common/wrapper/FlexRowWrapper";
import { supabase } from "../config/supabase";
import { WALLET_DB } from "../constants/db";
import { useStore } from "../store/store";
import { FaDiscord, FaTwitter, FaQuestionCircle } from "react-icons/fa";

const Card = styled(OriginalCard)`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(50px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const StyledTypography = styled(Typography)`
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
`;

const ICONS = [
  {
    IconComponent: FaDiscord,
    link: "https://discord.com/frenland",
  },
  {
    IconComponent: FaTwitter,
    link: "https://twitter.com/frendotland",
  },
  {
    IconComponent: FaQuestionCircle,
    link: "https://frenland.gitbook.io/",
  },
];

const IconContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const IconLink = styled.a`
  color: white;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
`;

const InviteCodeWrapper = styled(FlexRowWrapper)`
  background: black url("/frenlandlogo.jpeg") no-repeat center center;
  background-size: contain;
  margin: auto;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  .card-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .right {
    align-self: end;
  }
  .invites-anchor {
    color: ${(props) => props.theme.color.light};
  }
`;

interface InviteCodeInterface {}

const InviteCode = (_: InviteCodeInterface) => {
  const { inviteCode, updateInviteCode } = useStore();
  const navigate = useNavigate();

  const checkInvite = async () => {
    let { data: walletAuth, error } = await supabase
      .from(WALLET_DB)
      .select("*")
      .eq("invite_code", inviteCode);

    if (error) {
      alert(error.message);
      return;
    }
    if (walletAuth!.length > 0) {
      toast.success("Welcome to the party🎉");
      navigate("/verify-twitter");
      // updateInviteCount(inviteCode);
    } else {
      toast.error("Invalid Invite Code :(");
    }
  };

  return (
    <InviteCodeWrapper>
      <Card className="card-wrapper">
        <StyledTypography fontSize={48} fontWeight={800}>
          ENTER INVITE CODE
        </StyledTypography>

        <StyledInput
          placeholder="Format: bsl-<secret-code>"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateInviteCode(e.target.value)
          }
        />
        <StyledButton className="right" onClick={() => checkInvite()}>
          Verify Code
        </StyledButton>
        <a
          href="https://twitter.com/search?q=%23bsl-invite&src=typed_query&f=top"
          target="_blank"
          rel="noreferrer noopener"
          className="right invites-anchor"
        >
          Don't have an invite code? Find here!
        </a>
      </Card>
      <IconContainer>
        {ICONS.map(({ IconComponent, link }) => (
          <IconLink href={link} target="_blank" rel="noopener noreferrer">
            <IconComponent size={30} />
          </IconLink>
        ))}
      </IconContainer>
    </InviteCodeWrapper>
  );
};

export default InviteCode;
