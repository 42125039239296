export const coordinateGrid: { contractAddress: `0x${string}`; abi: any } = {
  contractAddress: "0x385386708e56357dcB5cad08eC4e3B5e892A7DfE",
  abi: [
    {
      inputs: [{ internalType: "address", name: "_treasury", type: "address" }],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "currentOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "prevOwners",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "coordId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "estateId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "isEstate",
          type: "bool",
        },
        {
          indexed: false,
          internalType: "string",
          name: "message",
          type: "string",
        },
      ],
      name: "CTrade",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "purchaser",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "coordinateId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "totalPrice",
          type: "uint256",
        },
      ],
      name: "CoordinatePurchased",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "estateId",
          type: "uint256",
        },
      ],
      name: "EstateBroken",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "estateId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256[]",
          name: "coordinates",
          type: "uint256[]",
        },
      ],
      name: "EstateCreated",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "purchaser",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "estateId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "totalPrice",
          type: "uint256",
        },
      ],
      name: "EstatePurchased",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "estateId",
          type: "uint256",
        },
      ],
      name: "EstateTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "purchaser",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "estateId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "fractionAmount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "totalPrice",
          type: "uint256",
        },
      ],
      name: "FractionalEstatePurchased",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "seller",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "estateId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "fractionAmount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "totalPrice",
          type: "uint256",
        },
      ],
      name: "FractionalEstateSold",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "uint256",
          name: "estateId",
          type: "uint256",
        },
        {
          indexed: true,
          internalType: "address",
          name: "trader",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "subject",
          type: "address",
        },
        { indexed: false, internalType: "bool", name: "isBuy", type: "bool" },
        {
          indexed: false,
          internalType: "uint256",
          name: "ethAmount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "protocolEthAmount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "subjectEthAmount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "supply",
          type: "uint256",
        },
      ],
      name: "Trade",
      type: "event",
    },
    {
      inputs: [],
      name: "addToTreasury",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "allCoordinates",
      outputs: [
        { internalType: "uint256[]", name: "", type: "uint256[]" },
        { internalType: "address[]", name: "", type: "address[]" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "allEstates",
      outputs: [
        { internalType: "uint256[]", name: "", type: "uint256[]" },
        { internalType: "address[]", name: "", type: "address[]" },
        { internalType: "uint256[][]", name: "", type: "uint256[][]" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "basePrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "estateId", type: "uint256" }],
      name: "breakEstate",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "estateId", type: "uint256" }],
      name: "buyEstate",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "estateId", type: "uint256" }],
      name: "buyNextFractionalEstate",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      name: "coordinates",
      outputs: [
        { internalType: "address", name: "owner", type: "address" },
        { internalType: "bool", name: "isEstate", type: "bool" },
        { internalType: "uint256", name: "estateId", type: "uint256" },
        { internalType: "uint256", name: "purchaseCount", type: "uint256" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256[]", name: "coordinateIds", type: "uint256[]" },
      ],
      name: "createEstate",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      name: "estates",
      outputs: [{ internalType: "address", name: "owner", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "fractionalEstateBasePrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "", type: "uint256" },
        { internalType: "address", name: "", type: "address" },
      ],
      name: "fractionalOwnership",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      name: "fractionsPurchased",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "wallet", type: "address" }],
      name: "getAllAddressData",
      outputs: [
        {
          components: [
            { internalType: "uint256", name: "estateId", type: "uint256" },
            {
              internalType: "uint256[]",
              name: "coordinates",
              type: "uint256[]",
            },
          ],
          internalType: "struct FracEstate.EstateInfo[]",
          name: "",
          type: "tuple[]",
        },
        { internalType: "uint256[]", name: "", type: "uint256[]" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getAllOwnersCount",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "wallet", type: "address" }],
      name: "getBrokenEstateCount",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "wallet", type: "address" }],
      name: "getEstateCount",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "estateId", type: "uint256" }],
      name: "getEstatePrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "supply", type: "uint256" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "getFEPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "pure",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "estateId", type: "uint256" }],
      name: "getFractionalEstatePrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
      name: "getOwnerAtIndex",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "coordinateId", type: "uint256" },
      ],
      name: "getPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256[]", name: "coordinateIds", type: "uint256[]" },
      ],
      name: "getTotalPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "estateId", type: "uint256" }],
      name: "listEstate",
      outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "listEstates",
      outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      name: "owners",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256[]", name: "coordinateIds", type: "uint256[]" },
      ],
      name: "purchaseCoordinates",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "estateId", type: "uint256" }],
      name: "sellFractionalEstate",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "newBasePrice", type: "uint256" },
      ],
      name: "setFractionalEstateBasePrice",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "_treasury", type: "address" }],
      name: "setTreasury",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "estateId", type: "uint256" },
        { internalType: "address", name: "newOwner", type: "address" },
      ],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "treasury",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
  ] as const,
};
