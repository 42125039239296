import axios from "axios";
import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Share } from "react-twitter-widgets";
import styled from "styled-components";
import { useAccount } from "wagmi";
import { Card as OriginalCard } from "../common/Card";
import StyledButton from "../common/StyledButton";
import StyledInput from "../common/StyledInput";
import Typography from "../common/Typography";
import FlexColumnWrapper from "../common/wrapper/FlexColumnWrapper";
import { supabase } from "../config/supabase";
import { TWITTER_DB } from "../constants/db";
import { TWITTER_TEXT_WITHOUT_REF_CODE } from "../constants/twitter";
import { FaDiscord, FaTwitter, FaQuestionCircle } from "react-icons/fa";
import QRCode from "qrcode.react";
import { isLg } from "../constants/responsive";

const StyledTypography = styled(Typography)`
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
`;

const Card = styled(OriginalCard)`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(50px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* ... other styles ... */
`;

const ICONS = [
  {
    IconComponent: FaDiscord,
    link: "https://discord.com/frenland",
  },
  {
    IconComponent: FaTwitter,
    link: "https://twitter.com/frendotland",
  },
  {
    IconComponent: FaQuestionCircle,
    link: "https://frenland.gitbook.io/",
  },
];

const IconContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const IconLink = styled.a`
  color: white;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;

const VerifyTwitterWrapper = styled(FlexColumnWrapper)`
  background: black url("/frenlandlogo.jpeg") no-repeat center center;
  background-size: contain;
  margin: auto;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  .twitter-card-wrapper {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  .right {
    align-self: end;
  }
  .red {
    color: red;
  }
  .green {
    color: red;
  }
  .btn-o {
    width: 214px !important;
  }
`;

const VerifyTwitter = () => {
  const [tweetLink, setTweetLink] = useState("");

  const { address } = useAccount();

  const tweetText = useMemo(() => {
    return TWITTER_TEXT_WITHOUT_REF_CODE + `bsl-${address?.slice(-8)}`;
  }, [address]);

  const tweetURL = useMemo(() => {
    return `https://x.com/intent/tweet?text=${encodeURIComponent(
      tweetText
    )}&hashtags=frenland,stackitup&url=https://testnet.fren.land`;
  }, [tweetText]);

  const [isVerified, setIsVerified] = React.useState(false);
  const [userExists, setUserExists] = React.useState(false);

  const navigate = useNavigate();

  const matchLinkPattern = () => {
    const pattern = /\/status\/(\d+)(?:\?(?:s|t)=\d+(?:&t=\w+)?)?/;

    const match = tweetLink.match(pattern);

    if (match) {
      const extractedNumber = match[1];
      return { tweetId: extractedNumber };
    } else {
      return { tweetId: false };
    }
  };

  const updateTwitterToDb = async (data: any) => {
    const { profile_image_url_https = "", screen_name = "", name = "" } = data;
    const { data: twitterUserData, error: twitterUserError } = await supabase
      .from(TWITTER_DB)
      .insert([
        {
          pfp: profile_image_url_https.replace("normal", "200x200"),
          username: screen_name,
          display_name: name,
          wallet_address: address,
        },
      ])
      .select();
    if (data) {
    }
    console.log(twitterUserData, twitterUserError);
  };

  const checkIfUserExists = async () => {
    let { data: twitter_info, error } = await supabase
      .from("twitter_info")
      .select("*")
      .eq("wallet_address", address);

    if (!error && twitter_info!.length > 0) {
      setUserExists(true);
    } else {
      setUserExists(false);
    }
  };
  const verifyTweet = async () => {
    const { tweetId } = matchLinkPattern();
    if (!tweetId) {
      toast.warning("Invalid Link");
      return;
    }
    const options = {
      method: "GET",
      url: "https://twitter135.p.rapidapi.com/v2/Tweet/",
      params: {
        id: tweetId,
      },
      headers: {
        "X-RapidAPI-Key": "d9acd25f07msh3f490b7d47027adp136021jsn3a90d7c6a3f9",
        "X-RapidAPI-Host": "twitter135.p.rapidapi.com",
      },
    };

    try {
      const response = await axios.request(options);
      if (response.data.text.includes(`bsl-${address?.slice(-8)}`)) {
        setIsVerified(true);
        updateTwitterToDb(response.data?.user);
        toast.success("Verified Twitter!");
        navigate("/plot");
        return;
      }
      toast.error("Please add YOUR tweet link :D");
      setIsVerified(false);
    } catch (error) {
      console.error(error);
      toast.error(`Error: ${error}`);
    }
  };

  React.useEffect(() => {
    checkIfUserExists();
    if (userExists) {
      navigate("/plot");
    }
    //eslint-disable-next-line
  }, [userExists]);

  return (
    <VerifyTwitterWrapper>
      <Card className="twitter-card-wrapper">
        <StyledTypography fontSize={48} fontWeight={800}>
          Let's mark your presence globally!
        </StyledTypography>

        {/* Step One */}
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <StyledTypography fontSize={12}>
            Step One: Click Tweet for verification ➔
          </StyledTypography>
          <div style={{ zIndex: 4 }}>
            <Share
              url="https://fren.land"
              options={{
                size: "large",
                hashtags: ["frenland", "stackitup"],
                text: tweetText,
              }}
            />
          </div>
          {window.innerWidth > 768 && (
            <div style={{ marginLeft: "20px" }}>
              <StyledTypography fontSize={12}>
                Tweet on Mobile:
              </StyledTypography>
              <QRCode value={tweetURL} />
            </div>
          )}
        </div>

        {/* Step Two */}
        <StyledTypography fontSize={12}>
          Step Two: Paste the link to the tweet ↴
        </StyledTypography>
        <StyledInput
          placeholder="Eg: https://x.com/<username>/status/<tweet_id>"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setTweetLink(e.target.value)
          }
        />

        <StyledButton className="right" onClick={() => verifyTweet()}>
          VERIFY TWEET
        </StyledButton>
        <div className={`${!isVerified ? "red" : "green"} right`}>
          {isVerified ? "Verified" : "Not verified"}
        </div>
      </Card>
      <IconContainer>
        {ICONS.map(({ IconComponent, link }) => (
          <IconLink href={link} target="_blank" rel="noopener noreferrer">
            <IconComponent size={30} />
          </IconLink>
        ))}
      </IconContainer>
    </VerifyTwitterWrapper>
  );
};

export default VerifyTwitter;
