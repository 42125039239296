import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useAccount } from "wagmi";
import StyledButton from "../common/StyledButton";
import Typography from "../common/Typography";
import FlexColumnWrapper from "../common/wrapper/FlexColumnWrapper";
import FlexRowWrapper from "../common/wrapper/FlexRowWrapper";
import { supabase } from "../config/supabase";
import { FEED_DB, TWITTER_DB } from "../constants/db";
import { eventEnum } from "../enum/eventEnum";
import { getCoordinatesFromCoordinateId } from "../helpers/coordHelpers";
import { truncateText } from "../helpers/truncateText";
import { updateNotificationStatus } from "../helpers/updateNotificationStatus";

const NotificationSidebarWrapper = styled(FlexColumnWrapper)`
  /* background: rgba(155, 131, 155, 0.5); */
  max-height: 300px;
  /* max-width: 400px; */
  max-width: fit-content;

  overflow-y: auto;
  box-shadow: 0 0 12px 2px rgba(51, 16, 55, 1);
  background: linear-gradient(
    103deg,
    rgba(51, 16, 55, 0.8) 14%,
    rgba(26, 7, 48, 0.8) 72%
  );
  border: 1px solid black;
  row-gap: 4px;
  border-radius: 16px;
  align-items: flex-start;
  color: #fff;
  position: absolute;
  justify-content: flex-start;
  width: 50%;
  margin: auto;
  backdrop-filter: blur(12px);
  right: 10px;
  .feed-row {
    align-items: center;
    column-gap: 10px;
    white-space: nowrap;
    &-2 {
      text-align: center;
      color: #ef6b6b;
    }
  }
  padding: 20px;
  z-index: 2;
  .new-notification {
    height: 8px;
    background: green;
    box-shadow: 0 0 5px 1px green;
    width: 8px;
    border-radius: 50%;
  }
  .void {
    height: 8px;
    border: 0.1px solid black;
    background: #1c1c1c;
    width: 8px;
    border-radius: 50%;
  }
  .load-more-notification {
    width: 100%;
    background: transparent;
    border: 1px solid #eee;
    color: #eee;
    font-size: 12px;
    &:hover {
      background: #1c1c1c;
    }
  }
`;

const PfpImage = styled.img`
  max-width: 30px;
  border-radius: 50%;
  max-height: 30px;
  width: fit-content;
`;
interface NotificationSidebarProps {
  className: string;
  unseenNotificationData: any;
}

const NotificationSidebar = ({
  className,
  unseenNotificationData,
}: NotificationSidebarProps): JSX.Element => {
  const queryClient = useQueryClient();
  const { address } = useAccount();

  const getNotification = async (min: number, max: number) => {
    let { data: notificationData, error } = await supabase
      .from(FEED_DB)
      .select(
        `*
        ,new_owner!inner(pfp,wallet_address, username),prev_owner!inner(pfp,wallet_address,username)`
      )
      .or(`prev_owner.eq.${address}`)
      .order("created_at", { ascending: false })
      .range(min, max);

    if (!error) {
      return notificationData;
    } else {
      console.log(error, "error");
    }
  };

  const {
    data: notificationData,
    fetchNextPage: fetchMoreNotification,
    isRefetching: isNotificationRefetching,
    isLoading: isNotificationLoading,
    hasNextPage: notificationHasNextPage,
    refetch: refetchNotification,
  } = useInfiniteQuery({
    queryKey: ["notification"],
    queryFn: async ({ pageParam = 0 }) =>
      getNotification(
        pageParam === 0 ? 0 : pageParam * 5 + 1,
        (pageParam + 1) * 8
      ),
    initialPageParam: 0,
    staleTime: Infinity,

    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    refetchOnMount: true,
    select: (data) => ({
      pages: [...data.pages],
      pageParams: [...data.pageParams],
    }),
  });

  const getActivityStr = (activity: string) => {
    switch (activity) {
      case eventEnum.PURCHASE_PLOT:
        return "PURCHASED";
      case eventEnum.RAID_ESTATE:
        return "ESTATE-RAID";
      case eventEnum.RAID_PLOT:
        return "PLOT-RAID";
      default:
        return "TRX";
    }
  };

  React.useEffect(() => {
    const timeout = setTimeout(
      () => queryClient.invalidateQueries({ queryKey: ["notification"] }),
      4000
    );
    return () => clearTimeout(timeout);
  }, []);
  React.useEffect(() => {
    if (unseenNotificationData?.length > 0) {
      updateNotificationStatus(address);
      queryClient.invalidateQueries({ queryKey: ["notification-count"] });
    }
  }, []);

  return (
    <NotificationSidebarWrapper className={className}>
      {notificationData &&
        notificationData?.pages?.map((notif: any) =>
          notif?.map((item: any) => (
            <>
              <FlexRowWrapper key={item.coord} className="feed-row feed-row-1">
                <div className={item.is_seen ? "void" : "new-notification"} />
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href={`https://x.com/${item.new_owner.username}`}
                >
                  <PfpImage
                    src={item.new_owner.pfp}
                    style={{
                      boxShadow: "0px 0px 15px 1px green",
                    }}
                  />
                </a>{" "}
                <Typography fontSize={12}>
                  {truncateText(item.new_owner.username, 6)}
                </Typography>
                {"-->"}
                <Typography fontSize={12}>
                  <FlexRowWrapper className="feed-row feed-row-2">
                    {getActivityStr(item.activity)}
                    {item.isEstate
                      ? `-Est.ID:${item.estateId}`
                      : `-(${getCoordinatesFromCoordinateId(item.coord)?.row},${
                          getCoordinatesFromCoordinateId(item.coord)?.column
                        })`}
                    -{item.amountInEth} ETH
                  </FlexRowWrapper>
                </Typography>
              </FlexRowWrapper>
            </>
          ))
        )}
      {notificationHasNextPage ? (
        <StyledButton
          onClick={fetchMoreNotification}
          className="load-more-notification"
        >
          Load More
        </StyledButton>
      ) : (
        <></>
      )}
    </NotificationSidebarWrapper>
  );
};

export default NotificationSidebar;
