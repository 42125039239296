import { supabase } from "../config/supabase";

export const updateNotificationStatus = async (
  wallet_address?: `0x${string}`
) => {
  let { data, error } = await supabase.rpc("updateisseen", {
    target_address: wallet_address,
  });

  if (error) {
    console.log(error, "notif error");
  } else {
    console.log(data, "notif data");
    return data;
  }
};
