import styled from "styled-components";

const CardWrapper = styled.div`
  border-radius: 15px;
  overflow: hidden;
  padding: 40px 60px;
  background: #1c1c1c;
  height: fit-content;
  width: fit-content;
  margin: auto;
  display: flex;
  width: 50%;
  flex-direction: column;
  row-gap: 20px;

  @media (max-width: 768px) {
    padding: 6% 13%;
  }
`;

interface CardInterface {
  className?: string;
  children: JSX.Element | any;
}

export const Card = ({ children, className }: CardInterface) => {
  return <CardWrapper className={className}>{children}</CardWrapper>;
};
