import React from "react";
import { UncontrolledReactSVGPanZoom } from "react-svg-pan-zoom";
import styled from "styled-components";
import { useAccount, useContractRead } from "wagmi";
import FlexColumnWrapper from "../../common/wrapper/FlexColumnWrapper";
import FlexRowWrapper from "../../common/wrapper/FlexRowWrapper";
import { supabase } from "../../config/supabase";
import { coordinateGrid } from "../../constants/contracts/coordinateGrid";
import { COORD_LEDGER_DB } from "../../constants/db";
import { grid } from "../../constants/grid";
import { useQueryClient } from "@tanstack/react-query";

import {
  MY_PLOTS_AND_ESTATES,
  SHOW_ONLY_ESTATES,
  SHOW_ONLY_PLOTS,
} from "../../constants/filter";
import StyledButton from "../../common/StyledButton";
import {
  getAllTwitterInfo,
  useGetAllTwitterInfo,
} from "../../helpers/functions/twitterData";
import { useStore } from "../../store/store";
import ReferralLeaderboard from "../ReferralLeaderboard";

const PlotInfo = React.lazy(() => import("./PlotInfo"));
const PlotFeed = React.lazy(() => import("./PlotFeed"));
const PlotLeaderboard = React.lazy(() => import("./PlotLeaderboard"));

const PlotMarketWrapper = styled.div`
  width: 100%;
  min-width: 90vw;
  svg {
    touch-action: none !important;
    fill: ${(props) => props.theme.color.dark}!important;
  }
  rect {
    border: 1px solid red;
    fill: transparent;
  }
  rect.wrapper-grid {
    pointer-events: unset !important;
  }
  .selected {
    fill: rgba(114, 255, 138, 0.7);
    stroke: rgb(114, 255, 138) !important;
    stroke-width: 15;
    z-index: 998;
    & ~ image {
      opacity: 0.2;
    }
  }
  .purchased {
    fill: rgba(255, 255, 255, 0.2);
    stroke: #1c1c1c;
  }
  .is-estate {
    stroke: #d9a86c;
    stroke-width: 15;
    stroke-linejoin: miter;
    z-index: 997;
  }
  .feed-leaderboard-wrapper {
    row-gap: 12px;
    width: 100%;
    height: 80%;
  }
  .react-svg-pan-zoom-navigator {
    display: none;
  }
`;

const GridInfoWrapper = styled(FlexRowWrapper)`
  column-gap: 24px;
`;

const GridBoxWrapper = styled(FlexRowWrapper)`
  width: 100%;
  column-gap: 24px;
  position: relative;
  cursor: pointer;
  z-index: 122;
  .clear-all-button {
    position: absolute;
    right: 25%;
    top: 8%;
    background: rgba(255, 0, 0, 0.8);
    box-shadow: 0px 0px 21px 2px red;
    color: ${(props) => props.theme.color.light};
    font-size: 12px;
    &:hover {
      background: rgba(255, 0, 0, 1);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    row-gap: 12px;
  }
`;

const PlotMarket: React.FC = () => {
  const queryClient = useQueryClient();
  const Viewer: any = React.useRef();
  const [currentTab, setCurrentTab] = React.useState("all");

  React.useEffect(() => {
    Viewer?.current?.fitToViewer();
    setCurrentTab("all");
  }, []);

  const {
    selectedCoordIds,
    setSelectedCoordIds,
    clearAllSelections,
    selectedEstates,
    setIsMapDragged,
    isMapDragged,
    setSelectedEstates,
  } = useStore((state) => ({
    selectedCoordIds: state.selectedCoordIds,
    setSelectedCoordIds: state.setSelectedCoordIds,
    clearAllSelections: state.clearAllSelections,
    selectedEstates: state.selectedEstates,
    setIsMapDragged: state.setIsMapDragged,
    isMapDragged: state.isMapDragged,
    setSelectedEstates: state.setSelectedEstates,
  }));

  const isEstateSquareShape = (estateCoords: number[]) => {
    const rows = estateCoords.map((coord) => Math.floor(coord / grid.size));
    const cols = estateCoords.map((coord) => coord % grid.size);
    const uniqueRows = Array.from(new Set(rows));
    const uniqueCols = Array.from(new Set(cols));
    return uniqueRows.length === uniqueCols.length;
  };

  const isPerfectSquare = (num: number) => {
    const sqrt = Math.sqrt(num);
    return sqrt - Math.floor(sqrt) === 0;
  };

  const getTopLeftCoordinate = (coords: number[]) => {
    let minX = Infinity;
    let minY = Infinity;

    coords.forEach((coord) => {
      const x = Math.floor(coord / grid.size);
      const y = coord % grid.size;
      if (x < minX) minX = x;
      if (y < minY) minY = y;
    });

    return { x: minX, y: minY };
  };

  const { address } = useAccount();

  const { data: allTwitterData } = useGetAllTwitterInfo();

  const {
    data: coordsData,
    isLoading: isCoordsLoading,
    refetch: refetchCoordsData,
  } = useContractRead({
    address: coordinateGrid.contractAddress,
    abi: coordinateGrid.abi,
    functionName: "allCoordinates",
    select: (data) => data?.[0].map((plotId: bigint) => Number(plotId)),
  });

  const {
    data: estateData,
    isLoading: isEstatesLoading,
    refetch: refetchEstatesData,
  } = useContractRead({
    address: coordinateGrid.contractAddress,
    abi: coordinateGrid.abi,
    functionName: "allEstates",
    //eslint-disable-next-line
    select: (data: any[]) => {
      const transformedData = [];
      for (let index = 0; index < data[0].length; index++) {
        const estateId = Number(data?.[0][index]);
        const wallet_address = data[1][index];
        const coords = data[2][index]?.map((c: bigint | undefined) =>
          Number(c)
        );

        if (coords.length > 0) {
          transformedData.push({
            estateId,
            wallet_address,
            coords,
          });
        }
      }
      return transformedData;
    },
  });

  const {
    data: myCoordsData,

    refetch: refetchMyCoordsData,
    isLoading: isMyCoordsLoading,
  } = useContractRead({
    address: coordinateGrid.contractAddress,
    abi: coordinateGrid.abi,
    functionName: "getAllAddressData",
    args: [address],

    select: (data) =>
      data?.map(() => {
        return [
          {
            estates: data[0].map((e: any, i: number) => ({
              estateId: Number(e?.estateId),
              coords: e?.coordinates?.map((c: bigint) => Number(c)),
            })),
            coords: data?.[1]?.map((c: bigint) => Number(c)),
          },
        ];
      })[0],
  });

  const getCoordinateIdFromCoordinates = (x: number, y: number) => {
    const coordinateId = x * grid.size + y;
    return coordinateId;
  };

  const dataToDisplay = React.useMemo(() => {
    const estateCoords = !isEstatesLoading
      ? estateData
          ?.map(({ coords }: { coords: number }) => coords)
          .flat(Infinity)
      : [];

    const myPlotAndEstates =
      !isMyCoordsLoading &&
      myCoordsData?.[0]?.estates
        ?.map((e: any) => e.coords)
        .concat(myCoordsData?.[0]?.coords)
        .flat(Infinity);

    const onlyCoords =
      !isCoordsLoading &&
      !isEstatesLoading &&
      coordsData?.filter(
        (element: number) => !estateCoords!?.includes(element)
      );

    switch (currentTab) {
      case SHOW_ONLY_PLOTS:
        return onlyCoords;
      case SHOW_ONLY_ESTATES:
        return estateCoords;
      case MY_PLOTS_AND_ESTATES:
        return myPlotAndEstates;
      case "all":
        return coordsData;
      default:
        return coordsData;
    }
  }, [
    currentTab,
    setCurrentTab,
    selectedCoordIds,
    selectedEstates,
    isCoordsLoading,
    isEstatesLoading,

    queryClient.getQueryState(["feed"])?.dataUpdateCount,
  ]);

  const getEstateId = () => {
    return estateData?.find(
      (item) =>
        item.coords.length === selectedCoordIds.length &&
        selectedCoordIds.every((coord: any) =>
          item.coords.includes(coord.coord_id)
        )
    )?.estateId;
  };

  const dtd = (row: any, col: any) => {
    return dataToDisplay?.includes(getCoordinateIdFromCoordinates(row, col));
  };

  type CoordData = {
    coord_id: number;
    username: string;
  };

  const storeSelectedCoords = (row: number, col: number) => {
    const coordinateId: number = getCoordinateIdFromCoordinates(row, col);
    const estate = estateData?.find((item) =>
      item.coords.includes(coordinateId)
    );

    if (estate) {
      const estateCoordIds: CoordData[] = estate?.coords?.map((coord: any) => ({
        coord_id: coord,
        username: getSelectedTwitter(row, col)?.username ?? "",
      }));

      console.log(estateCoordIds, selectedCoordIds, "estate coord ids");
      const areAnySelected = estateCoordIds?.some((eCoord: CoordData) =>
        selectedCoordIds?.find(
          (sCoord: any) => sCoord?.coord_id === eCoord?.coord_id
        )
      );

      if (areAnySelected) {
        console.log(selectedCoordIds, "selected coord ids");
        setSelectedCoordIds(
          selectedCoordIds.filter(
            (sCoord) =>
              !estateCoordIds.some(
                (eCoord: CoordData) => eCoord.coord_id === sCoord.coord_id
              )
          )
        );
      } else {
        setSelectedCoordIds([...selectedCoordIds, ...estateCoordIds]);
        console.log(selectedCoordIds, "scew");
      }

      if (selectedEstates.includes(estate.estateId)) {
        setSelectedEstates(
          selectedEstates.filter((id: any) => id !== estate.estateId)
        );
      } else {
        setSelectedEstates([...selectedEstates, estate.estateId]);
      }
    } else {
      if (
        Array.isArray(selectedCoordIds) &&
        !selectedCoordIds?.find((coord: any) => coord.coord_id === coordinateId)
      ) {
        setSelectedCoordIds([
          ...selectedCoordIds,
          {
            coord_id: coordinateId,
            username: getSelectedTwitter(row, col)?.username,
          },
        ]);
      } else {
        let updated = selectedCoordIds.filter(
          ({ coord_id }: any) => coord_id !== coordinateId
        );
        setSelectedCoordIds(updated);
      }
    }
  };

  const getSelectedTwitter = (row: number, col: number): any => {
    return allTwitterData?.data?.find(
      (coord: any) =>
        coord?.coord_id === getCoordinateIdFromCoordinates(row, col)
    )?.twitter_info;
  };

  return (
    <PlotMarketWrapper>
      <GridInfoWrapper>
        <PlotInfo
          setCurrentTab={setCurrentTab}
          estateId={getEstateId()}
          currentTab={currentTab}
          refetchCoordsData={refetchCoordsData}
          refetchMyCoordsData={refetchMyCoordsData}
          refetchEstatesData={refetchEstatesData}
        />

        <GridBoxWrapper>
          <FlexColumnWrapper className="feed-leaderboard-wrapper">
            <PlotFeed />
            <ReferralLeaderboard />
            <PlotLeaderboard />
          </FlexColumnWrapper>
          <FlexRowWrapper>
            {selectedCoordIds.length > 0 ? (
              <StyledButton
                onClick={clearAllSelections}
                className="clear-all-button"
              >
                Clear All
              </StyledButton>
            ) : (
              <></>
            )}
            <UncontrolledReactSVGPanZoom
              width={window.innerWidth > 576 ? 1000 : window.innerWidth}
              height={window.innerWidth > 576 ? 700 : 400}
              ref={Viewer}
              className="wrapper-grid"
              background="transparent"
              toolbarProps={{ position: "top" }}
              miniatureProps={{
                position: "none",
                background: "transparent",
                width: 0,
                height: 0,
              }}
              preventPanOutside
              defaultTool="pan"
            >
              <svg
                width={grid.size * grid.squareSize}
                height={grid.size * grid.squareSize}
                className="plot-parent"
                style={{
                  pointerEvents: "unset",
                  background: "#000",
                  cursor: "pointer",
                  zIndex: "124",
                }}
              >
                {Array.from({ length: grid.size }).map((_, row) =>
                  Array.from({ length: grid.size }).map((_, col) => (
                    <g
                      style={{
                        pointerEvents: "unset",
                        cursor: "pointer",
                        zIndex: "124",
                      }}
                      key={getCoordinateIdFromCoordinates(row, col)}
                      onClick={(e) => {
                        e.preventDefault();
                        storeSelectedCoords(row, col);
                      }}
                      // onDoubleClick={() => storeSelectedCoords(row, col)}
                    >
                      <rect
                        style={{
                          pointerEvents: "unset",
                          cursor: "pointer",
                        }}
                        x={col * grid.squareSize}
                        y={row * grid.squareSize}
                        className={`
                        ${
                          !isCoordsLoading && dtd(row, col)
                            ? !isEstatesLoading &&
                              estateData?.map((item: any) =>
                                item?.coords?.includes(
                                  getCoordinateIdFromCoordinates(row, col)
                                )
                                  ? " is-estate "
                                  : " purchased "
                              )
                            : "this"
                        }
                      ${
                        Array.isArray(selectedCoordIds) &&
                        selectedCoordIds?.find(
                          (coord: any) =>
                            coord?.coord_id ===
                            getCoordinateIdFromCoordinates(row, col)
                        )
                          ? "selected"
                          : ""
                      }`}
                        width={grid.squareSize}
                        height={grid.squareSize}
                        stroke="#eee"
                        strokeWidth="1"
                        fill="transparent"
                      />

                      {allTwitterData?.data && !!dtd(row, col) ? (
                        (() => {
                          const estate = estateData?.find(
                            (estate) =>
                              isEstateSquareShape(estate.coords) &&
                              estate.coords.includes(
                                getCoordinateIdFromCoordinates(row, col)
                              )
                          );

                          if (estate && currentTab !== SHOW_ONLY_PLOTS) {
                            const smallestCoord = Math.min(...estate.coords);
                            const smallestRow = Math.floor(
                              smallestCoord / grid.size
                            );
                            const smallestCol = smallestCoord % grid.size;

                            return (
                              <image
                                x={smallestCol * grid.squareSize}
                                y={smallestRow * grid.squareSize}
                                width={
                                  grid.squareSize *
                                  Math.sqrt(estate.coords.length)
                                }
                                height={
                                  grid.squareSize *
                                  Math.sqrt(estate.coords.length)
                                }
                                xlinkHref={
                                  getSelectedTwitter(smallestRow, smallestCol)
                                    ?.pfp
                                }
                                imageRendering="pixelated"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  storeSelectedCoords(row, col);
                                }}
                                // onDoubleClick={(e) => {
                                //   e.preventDefault();
                                //   storeSelectedCoords(row, col);
                                // }}
                              />
                            );
                          } else {
                            return (
                              <image
                                x={col * grid.squareSize}
                                y={row * grid.squareSize}
                                width={grid.squareSize}
                                height={grid.squareSize}
                                xlinkHref={getSelectedTwitter(row, col)?.pfp}
                                style={{ cursor: "pointer" }}
                                imageRendering="pixelated"
                                onClick={(e) => {
                                  e.preventDefault();
                                  storeSelectedCoords(row, col);
                                }}
                                // onDoubleClick={(e) => {
                                //   e.preventDefault();
                                //   storeSelectedCoords(row, col);
                                // }}
                              />
                            );
                          }
                        })()
                      ) : (
                        <></>
                      )}
                    </g>
                  ))
                )}
                {Array.from({ length: grid.size }).map((_, row) =>
                  Array.from({ length: grid.size }).map((_, col) => {
                    const estate = estateData?.find(
                      (estate) =>
                        isEstateSquareShape(estate.coords) &&
                        estate.coords.includes(
                          getCoordinateIdFromCoordinates(row, col)
                        )
                    );

                    if (
                      !!dtd(row, col) &&
                      estate &&
                      currentTab !== SHOW_ONLY_PLOTS
                    ) {
                      const smallestCoord = Math.min(...estate.coords);
                      const smallestRow = Math.floor(smallestCoord / grid.size);
                      const smallestCol = smallestCoord % grid.size;

                      return (
                        <g
                          style={{
                            pointerEvents: "unset",
                            cursor: "pointer",
                          }}
                          key={getCoordinateIdFromCoordinates(row, col)}
                          // onDoubleClick={() => storeSelectedCoords(row, col)}
                          onClick={() => storeSelectedCoords(row, col)}
                        >
                          <image
                            x={smallestCol * grid.squareSize}
                            y={smallestRow * grid.squareSize}
                            width={
                              grid.squareSize * Math.sqrt(estate.coords.length)
                            }
                            height={
                              grid.squareSize * Math.sqrt(estate.coords.length)
                            }
                            xlinkHref={
                              getSelectedTwitter(smallestRow, smallestCol)?.pfp
                            }
                            imageRendering="pixelated"
                            onClick={() => storeSelectedCoords(row, col)}
                            onDoubleClick={(e) => {
                              e.preventDefault();
                              storeSelectedCoords(row, col);
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          />
                          <rect
                            x={smallestCol * grid.squareSize}
                            y={smallestRow * grid.squareSize}
                            width={
                              grid.squareSize * Math.sqrt(estate.coords.length)
                            }
                            height={
                              grid.squareSize * Math.sqrt(estate.coords.length)
                            }
                            className={
                              Array.isArray(selectedEstates) &&
                              selectedEstates.includes(estate.estateId)
                                ? "selected"
                                : "is-estate"
                            }
                            fill="none"
                          />
                        </g>
                      );
                    } else {
                      return null;
                    }
                  })
                )}
              </svg>
            </UncontrolledReactSVGPanZoom>
          </FlexRowWrapper>
        </GridBoxWrapper>
      </GridInfoWrapper>
    </PlotMarketWrapper>
  );
};

export default PlotMarket;
