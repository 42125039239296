import { ConnectButton } from "@rainbow-me/rainbowkit";
import React from "react";
import styled from "styled-components";
import Logo from "../assets/frenlandlogo.png";
import { ReactComponent as TrophyIcon } from "../assets/trophy.svg";
import { ReactComponent as BellIcon } from "../assets/bell.svg";

import Typography from "../common/Typography";
import FlexRowWrapper from "../common/wrapper/FlexRowWrapper";
import { color } from "../constants/colors";
import NotificationSidebar from "./NotificationSidebar";
import { supabase } from "../config/supabase";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { FEED_DB } from "../constants/db";
import { useAccount } from "wagmi";
import { isLg } from "../constants/responsive";

const HeaderWrapper = styled(FlexRowWrapper)`
  padding: 15px 40px;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 15px 0px;
  }
  .button-wrapper {
    column-gap: 24px;
    align-items: center;
    .refetch-button {
      font-size: 14px;
    }
  }
  transition: all 1s;
  .airdrop-pool-wrapper {
    /* box-shadow: 0px 0px 0px 3px rgba(148, 0, 211, 0.5),
      0px 0px 0px 6px rgba(75, 0, 130, 0.5),
      0px 0px 0px 9px rgba(0, 0, 255, 0.5),
      0px 0px 0px 12px rgba(0, 255, 0, 0.5),
      0px 0px 0px 15px rgba(255, 255, 0, 0.5),
      0px 0px 0px 18px rgba(255, 127, 0, 0.5),
      0px 0px 0px 21px rgba(255, 0, 0, 0.5); */
    align-items: center;
    border-radius: 10px;
    padding: 4px 6px;
    background: linear-gradient(
      45deg,
      rgba(255, 0, 0, 1) 0%,
      rgba(255, 154, 0, 1) 10%,
      rgba(208, 222, 33, 1) 20%,
      rgba(79, 220, 74, 1) 30%,
      rgba(63, 218, 216, 1) 40%,
      rgba(47, 201, 226, 1) 50%,
      rgba(28, 127, 238, 1) 60%,
      rgba(95, 21, 242, 1) 70%,
      rgba(186, 12, 248, 1) 80%,
      rgba(251, 7, 217, 1) 90%,
      rgba(255, 0, 0, 1) 100%
    );
    @media (max-width: 768px) {
      height: 32px;
    }
  }

  .logo-wrapper {
    align-items: flex-end;
    column-gap: 8px;
  }

  .notification-wrapper {
    cursor: pointer;
    position: relative;
    .show {
      width: fit-content;
    }
    .hide {
      width: 0px;
    }

    .icon-wrapper {
      position: relative;

      .count-badge {
        position: absolute;
        top: -4px;
        right: -4px;
        font-size: 8px;
        font-size: 12px;
        min-height: 18px;
        padding: 3px;
        min-width: 18px;
        color: #000;
        border-radius: 50px;
        background: #b5ef4b;
        text-align: center;
      }
    }
  }
`;

interface HeaderProps {}

const Header = (_: HeaderProps): JSX.Element => {
  //eslint-disable-next-line
  const [currentPool, setCurrentPool] = React.useState(5);
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const { address } = useAccount();

  const getUnseenNotificationCount = async () => {
    let { data: unseenNotificationData, error } = await supabase
      .from(FEED_DB)
      .select(
        `*,new_owner!inner(pfp,wallet_address, username),prev_owner!inner(pfp,wallet_address,username)`
      )
      .or(`is_seen.eq.false`)
      .or(`prev_owner.eq.${address}`)
      .order("created_at", { ascending: false });

    if (!error) {
      return unseenNotificationData;
    } else {
    }
  };

  const {
    data: unseenNotificationData,
    isRefetching: isNotificationRefetching,
    isLoading: isNotificationLoading,
    refetch: refetchNotification,
  } = useQuery({
    queryKey: ["notification-count"],
    queryFn: () => getUnseenNotificationCount(),
    staleTime: Infinity,
    refetchOnMount: true,
    select: (data) => data,
  });

  // const refetchPfp = async () => {
  //   let { data: coord_ledger, error } = await supabase
  //     .from(COORD_LEDGER_DB)
  //     .select("*")
  //     .eq("wallet_address", address);
  // };

  return (
    <HeaderWrapper>
      <FlexRowWrapper className="logo-wrapper">
        <img height={65} width={65} src={Logo} />
        {isLg && (
          <Typography fontSize={48} fontWeight={100}>
            fren.land
          </Typography>
        )}
      </FlexRowWrapper>
      <FlexRowWrapper className="button-wrapper">
        {isLg && <ConnectButton />}
        <FlexRowWrapper className="airdrop-pool-wrapper">
          <TrophyIcon width={28} height="fit-content" />
          <Typography fontWeight={700} textcolor={color.grey} fontSize={16}>
            {isLg && "Airdrop Pool:"}
            {currentPool} ETH
          </Typography>
        </FlexRowWrapper>

        <div className="notification-wrapper">
          <div
            onClick={() => setIsNotificationOpen((prev) => !prev)}
            className="icon-wrapper"
          >
            <BellIcon
              height={35}
              width={35}
              color={
                unseenNotificationData?.length! > 0 ? "transparent" : "#410867"
              }
            />
            {unseenNotificationData?.length! > 0 && (
              <Typography className="count-badge">
                {unseenNotificationData?.length}
              </Typography>
            )}
          </div>
          {isNotificationOpen ? (
            <NotificationSidebar
              unseenNotificationData={unseenNotificationData}
              className={isNotificationOpen ? "show" : "hide"}
            />
          ) : (
            <></>
          )}
        </div>

        {/* <StyledButton className="refetch-button">Refetch</StyledButton> */}
      </FlexRowWrapper>
    </HeaderWrapper>
  );
};

export default Header;
