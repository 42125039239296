import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled, { keyframes } from "styled-components";
import { useAccount } from "wagmi";
import { Card as OriginalCard } from "../common/Card";
import Typography from "../common/Typography";
import FlexColumnWrapper from "../common/wrapper/FlexColumnWrapper";
import { supabase } from "../config/supabase";
import { WALLET_DB } from "../constants/db";
import { FaDiscord, FaTwitter, FaQuestionCircle } from "react-icons/fa";

const pumpAnimation = keyframes`
  0%{
    transform: scale(1);
  };
  50%{
    transform: scale(1.03);
  };
  100%{
    transform: scale(1);
  }
`;

const Card = styled(OriginalCard)`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(50px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: ${pumpAnimation} 3s;
  animation-iteration-count: infinite;
`;

const StyledTypography = styled(Typography)`
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
`;

const ICONS = [
  {
    IconComponent: FaDiscord,
    link: "https://discord.com/frenland",
  },
  {
    IconComponent: FaTwitter,
    link: "https://twitter.com/frendotland",
  },
  {
    IconComponent: FaQuestionCircle,
    link: "https://frenland.gitbook.io/",
  },
];

const IconContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const IconLink = styled.a`
  color: white;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;

const ConnectWalletWrapper = styled(FlexColumnWrapper)`
  background: black url("/frenlandlogo.png") no-repeat center center;
  background-size: contain;
  margin: auto;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  .right {
    align-self: end;
  }
`;

interface ConnectWalletInterface {}

const ConnectWallet = (_: ConnectWalletInterface) => {
  const navigate = useNavigate();

  //eslint-disable-next-line
  const account = useAccount({
    onConnect({ address }) {
      checkUser(address!);
    },
  });

  const checkUser = async (address: `0x${string}`) => {
    let { data: walletAuth, error } = await supabase
      .from(WALLET_DB)
      .select("*")
      .eq("wallet_address", address);
    if (error) {
      toast.error(`Error: ${error}`);
    }

    if (walletAuth!.length > 0) {
      navigate("/verify-twitter");
    } else {
      addUserToDb(address);
    }
  };
  const addUserToDb = async (address: `0x${string}`) => {
    const { data: userData, error: userError } = await supabase
      .from(WALLET_DB)
      .insert([
        {
          wallet_address: address,
          invite_code: `bsl-${address.slice(-8)}`,
        },
      ])
      .select();
    if (userError) {
      toast.error(`Error: ${userError.message}`);
    }
    if (userData!.length > 0) {
      navigate("/invite");
    }
  };

  return (
    <ConnectWalletWrapper>
      <Card>
        <StyledTypography fontSize={48} fontWeight={800}>
          How do we {"0x<address>"} you?
        </StyledTypography>
        <div className="right">
          <ConnectButton />
        </div>
      </Card>
      <IconContainer>
        {ICONS.map(({ IconComponent, link }) => (
          <IconLink href={link} target="_blank" rel="noopener noreferrer">
            <IconComponent size={30} />
          </IconLink>
        ))}
      </IconContainer>
    </ConnectWalletWrapper>
  );
};

export default ConnectWallet;
