import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { supabase } from "../../config/supabase";
import { TWITTER_DB } from "../../constants/db";

export const getReferalInfo = async (min: number, max: number) => {
  let { data, error, status } = await supabase
    .from(TWITTER_DB)
    .select(
      `
        username,
        display_name,
        pfp,
        referal_count
    `
    )
    .order("referal_count", { ascending: false })
    .range(min, max);

  if (!error) {
    return data;
  } else {
    return error;
  }
};

// remove all referral naming & keep "data, error" for passing data
export const useGetReferalInfo = () => {
  const {
    data: referalInfoData,
    error: referalError,
    status: referalStatus,
    isFetching: isReferalInfoFetching,
    isLoading: isReferalInfoLoading,
    hasNextPage: referalInfoHasNextPage,
    fetchNextPage: fetchReferalInfoNextPage,
  } = useInfiniteQuery({
    queryKey: ["referal-info"],
    queryFn: async ({ pageParam = 0 }) =>
      getReferalInfo(
        pageParam === 0 ? 0 : pageParam * 5 + 1,
        (pageParam + 1) * 8
      ),

    initialPageParam: 0,
    staleTime: Infinity, // cache forever because this is a static value that doesn't change
    getNextPageParam: (lastPage: any, allPages, lastPageParam) => {
      if (lastPage?.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    refetchOnMount: true,
    select: (data) => ({
      pages: [...data.pages],
      pageParams: [...data.pageParams],
    }),
  });
  return {
    referalInfoData,
    referalError,
    referalStatus,
    isReferalInfoFetching,
    isReferalInfoLoading,
    referalInfoHasNextPage,
    fetchReferalInfoNextPage,
  };
};
