import styled from "styled-components";
import { color } from "../constants/colors";

const TypographyWrapper = styled.div<{
  fontSize: number;
  fontWeight: number;
  textcolor: string;
  underlined: boolean | undefined;
}>`
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.textcolor};
  border-bottom: ${(props) =>
    props.underlined && `1px solid ${props.textcolor}`};
  z-index: 11;

  @media (max-width: 768px) {
    font-size: ${(props) => props.fontSize / 1.5}px;
  }
  &:hover {
    opacity: ${(props) => props.underlined && 0.8};
  }
`;

interface TypographyProps {
  fontSize?: number;
  fontWeight?: number;
  className?: string;

  textcolor?: string;
  underlined?: boolean | undefined;
  children: string | any;
}

const Typography = ({
  fontSize = 48,
  fontWeight = 600,
  textcolor = color.light,
  className = "",
  underlined = undefined,
  children,
}: TypographyProps): JSX.Element => {
  return (
    <TypographyWrapper
      className={className}
      textcolor={textcolor}
      fontSize={fontSize}
      underlined={underlined}
      fontWeight={fontWeight}
    >
      {children}
    </TypographyWrapper>
  );
};

export default Typography;
