import "@rainbow-me/rainbowkit/styles.css";

import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { baseGoerli } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";

import { publicProvider } from "wagmi/providers/public";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import ConnectWallet from "./components/ConnectWallet";
import InviteCode from "./components/InviteCode";
import PlotMarket from "./components/plot/index";
import VerifyTwitter from "./components/VerifyTwitter";
import { Suspense } from "react";
import Loader from "./common/Loader";
import { PageLoader } from "./common/PageLoader";

const AppWrapper = styled.div`
  font-family: "Red Hat Mono", monospace, sans-serif;
  min-height: 103.25vh;
  min-width: 100vw;
  overflow-x: hidden;
  margin: -10px;
  background: ${(props) => props.theme.color.dark};
  background-size: 50px 50px;

  z-index: -1;
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.05) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 1px, transparent 1px);

  /* Add these properties to control background clipping */
  background-clip: content-box;
  background-origin: content-box;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: 50px 50px;
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.05) 1px,
        transparent 1px
      ),
      linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
`;

const App = () => {
  const { chains, publicClient } = configureChains(
    [baseGoerli],
    [
      alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY! }),
      publicProvider(),
    ]
  );

  function getLibrary(provider: any) {
    const library = new Web3Provider(provider);
    return library;
  }

  const { connectors } = getDefaultWallets({
    appName: "frenland",
    projectId: process.env.REACT_APP_WALLETCONNECT_API_KEY!,
    chains,
  });

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
    // persister: null,
  });

  //react-query
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
      },
    },
  });

  return (
    <AppWrapper>
      <HelmetProvider>
        <Helmet>
          <title>fren.land</title>
          <link rel="canonical" href="https://testnet.fren.land" />
          <meta name="description" content="fren.land" />
        </Helmet>

        <QueryClientProvider client={queryClient}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <WagmiConfig config={wagmiConfig}>
              <RainbowKitProvider chains={chains}>
                <Routes>
                  <Route path="/invite" element={<InviteCode />} />
                  <Route path="/" element={<ConnectWallet />} />
                  <Route path="/loader" element={<PageLoader />} />

                  <Route path="/verify-twitter" element={<VerifyTwitter />} />
                  <Route
                    path="/plot"
                    element={
                      <Suspense fallback={<PageLoader />}>
                        <PlotMarket />
                      </Suspense>
                    }
                  />
                </Routes>
              </RainbowKitProvider>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="dark"
              />
            </WagmiConfig>
          </Web3ReactProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </AppWrapper>
  );
};

export default App;
