import { grid } from "../constants/grid";

export const getCoordinatesFromCoordinateId = (coordinateId: number) => {
  const row = Math.floor(coordinateId / grid.size);
  const column = coordinateId % grid.size;
  return { row, column };
};

export const getCoordinateIdFromCoordinates = (x: number, y: number) => {
  const coordinateId = x * grid.size + y;
  return coordinateId;
};
