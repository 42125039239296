import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "../config/supabase";
import { TWITTER_DB } from "../constants/db";
import { useGetReferalInfo } from "../helpers/functions/getReferalInfo";
import Typography from "../common/Typography";
import FlexRowWrapper from "../common/wrapper/FlexRowWrapper";
import Loader from "../common/Loader";
import React from "react";
import StyledButton from "../common/StyledButton";

const ReferralLeaderboardWrapper = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  backdrop-filter: blur(50px);
  box-shadow: 0 4px 6px 0 rgba(31, 38, 135, 0.37);
  height: fit-content;
  .pg-title {
    padding: 20px 20px 0;
  }
  .loaders-wrapper {
    gap: 12px;
    padding: 20px;
  }
  .scroll-wrapper {
    display: flex;
    flex-direction: column;
    padding: 15px 20px 0;
    z-index: 3;
    row-gap: 4px;

    height: 110px;
    overflow-y: auto;
    .refer-row {
      align-items: center;
      column-gap: 10px;
      &-1 {
        justify-content: space-between;
      }
      &-2 {
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
  }
  a {
    text-decoration: none;
  }
  .load-more-button {
    width: 100%;

    height: fit-content;
    font-size: 12px;
    background: rgba(255, 255, 255, 0.8);
    color: #000;
    &:hover {
      background: #fff;
    }
  }
`;

const PfpImage = styled.img`
  max-width: 32px;
  border-radius: 50%;
  max-height: 32px;
`;

interface ReferralLeaderboardProps {}

const ReferralLeaderboard = (_: ReferralLeaderboardProps): JSX.Element => {
  const {
    referalInfoData,
    referalError,
    fetchReferalInfoNextPage,
    isReferalInfoLoading,
    referalInfoHasNextPage,
  } = useGetReferalInfo();

  return (
    <ReferralLeaderboardWrapper>
      <Typography className="pg-title" fontSize={16}>
        Referral Count
      </Typography>

      {isReferalInfoLoading ? (
        <FlexRowWrapper className="loaders-wrapper">
          <Loader />
          <Loader />
          <Loader />
          <Loader />
          <Loader />
        </FlexRowWrapper>
      ) : (
        <div className="scroll-wrapper">
          {referalInfoData?.pages?.map((pg: any, i: number) => (
            <React.Fragment key={i}>
              {pg?.map((item: any, index: number) => (
                <FlexRowWrapper key={index} className="refer-row refer-row-1">
                  <a href={`https://x.com/${item?.username}`}>
                    <FlexRowWrapper className="refer-row">
                      <PfpImage
                        src={item?.pfp}
                        style={{
                          boxShadow: "0px 0px 15px 1px green",
                        }}
                      />

                      <Typography fontSize={12}>@{item?.username}</Typography>
                    </FlexRowWrapper>
                  </a>
                  <div>
                    <Typography fontSize={12}>{item?.referal_count}</Typography>
                  </div>
                </FlexRowWrapper>
              ))}
            </React.Fragment>
          ))}

          {referalInfoHasNextPage ? (
            <StyledButton
              className="load-more-button"
              onClick={fetchReferalInfoNextPage}
            >
              {isReferalInfoLoading ? (
                <>
                  Loading...
                  <Loader />
                </>
              ) : (
                "Load More"
              )}
            </StyledButton>
          ) : (
            <></>
          )}
        </div>
      )}
    </ReferralLeaderboardWrapper>
  );
};

export default ReferralLeaderboard;
