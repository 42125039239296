import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { supabase } from "../../config/supabase";
import { COORD_LEDGER_DB } from "../../constants/db";

export const getAllTwitterInfo = async () => {
  let { data, error, status } = await supabase.from(COORD_LEDGER_DB).select(`
      coord_id,
      wallet_address,
      twitter_info (
          username,
          pfp
        )
    `);
  return {
    data,
    error,
    status,
  };
};

export const useGetAllTwitterInfo = () => {
  const {
    data: allTwitterData,
    error: allTwitterError,
    status: allTwitterStatus,
    isFetching: isAllTwitterInfoFetching,
  } = useQuery({
    queryKey: ["all-twitter"],
    queryFn: async () => await getAllTwitterInfo(),
    refetchOnMount: true,

    staleTime: Infinity, // cache forever because this is a static value that doesn't change
    refetchOnReconnect: true,
  });
  return {
    data: allTwitterData,
    error: allTwitterError,
    status: allTwitterStatus,
    isFetching: isAllTwitterInfoFetching,
  };
};
