import styled from "styled-components";

const StyledButtonWrapper = styled.button`
  cursor: pointer;
  background-color: ${(props) => props.theme.color.light};
  font-weight: 700;
  padding: 10px 20px;
  text-align: center;
  outline: none;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: fit-content;
  height: fit-content;
  z-index: 122;
  color: ${(props) => props.theme.color.grey};
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  font-size: 18px;
  border-radius: 10px;
  &:hover {
    background-color: ${(props) => props.theme.color.medium};
    transform: translateY(-2px);
    color: ${(props) => props.theme.color.dark};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

interface StyledButtonProps {
  children: any | string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const StyledButton = ({
  children,
  className,
  disabled,
  onClick,
}: StyledButtonProps): JSX.Element => {
  return (
    <StyledButtonWrapper
      disabled={disabled}
      className={className}
      onClick={onClick}
    >
      {children}
    </StyledButtonWrapper>
  );
};

export default StyledButton;

