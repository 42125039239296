import styled from "styled-components";
import FlexRowWrapper from "../../common/wrapper/FlexRowWrapper";
import Header from "../Header";
import PlotMarket from "./PlotMarket";

const IndexWrapper = styled.div`
  padding: 0 20px;
`;

interface IndexProps {}

const Index = (_: IndexProps): JSX.Element => {
  return (
    <IndexWrapper>
      <Header />
      <FlexRowWrapper>
        {/* <PlotInfo /> */}
        {/* <PlotLog /> */}
        <PlotMarket />
      </FlexRowWrapper>
    </IndexWrapper>
  );
};

export default Index;
