import { create } from "zustand";

interface UserOnboardInterface {
  inviteCode: string;
  updateInviteCode: (code: string) => void;
  isMapDragged: boolean;
  setIsMapDragged: (currState: boolean) => void;

  isBuyingCoords: boolean;
  setIsBuyingCoords: (currState: boolean) => void;

  isBuyingEstate: boolean;
  setIsBuyingEstate: (currState: boolean) => void;

  isCreatingEstate: boolean;
  setIsCreatingEstate: (currState: boolean) => void;

  isBreakingEstate: boolean;
  setIsBreakingEstate: (currState: boolean) => void;

  clearAllContractInteractions: () => void;

  selectedCoordIds: { coord_id: number; username: string }[];
  setSelectedCoordIds: (
    prevSelected: { coord_id: number; username: string }[]
  ) => void;

  selectedEstates: number[];
  setSelectedEstates: (state: any[]) => void;

  clearAllSelections: () => void;
}

export const useStore = create<UserOnboardInterface>()((set) => ({
  inviteCode: "",
  updateInviteCode: (code) => set({ inviteCode: code }),

  isMapDragged: false,
  setIsMapDragged(currState) {
    set({ isMapDragged: currState });
  },
  // buy coords
  isBuyingCoords: false,
  setIsBuyingCoords(currState) {
    set(() => ({
      isBuyingCoords: currState,
    }));
  },
  // buy estate
  isBuyingEstate: false,
  setIsBuyingEstate(currState) {
    set(() => ({
      isBuyingEstate: currState,
    }));
  },

  // create estate
  isCreatingEstate: false,
  setIsCreatingEstate(currState) {
    set(() => ({
      isCreatingEstate: currState,
      isBuyingCoords: false,
      isBuyingEstate: false,
      isBreakingEstate: false,
    }));
  },

  // break estate
  isBreakingEstate: false,
  setIsBreakingEstate(currState) {
    set(() => ({
      isBreakingEstate: currState,
      isBuyingCoords: false,
      isBuyingEstate: false,
      isCreatingEstate: false,
    }));
  },

  clearAllContractInteractions() {
    set(() => ({
      isBreakingEstate: false,
      isBuyingCoords: false,
      isBuyingEstate: false,
      isCreatingEstate: false,
    }));
  },

  selectedCoordIds: [],
  setSelectedCoordIds(currState: { coord_id: number; username: string }[]) {
    set((state) => ({
      selectedCoordIds: currState ?? [],
    }));
  },

  selectedEstates: [],
  setSelectedEstates(currState: any[]) {
    set((state) => ({
      selectedEstates: currState,
    }));
  },

  clearAllSelections() {
    set((state) => ({
      selectedCoordIds: [],
      selectedEstates: [],
    }));
  },
}));
