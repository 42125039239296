import PageLoaderGif from "../assets/loader.gif";
import FlexRowWrapper from "./wrapper/FlexRowWrapper";

const PageGif = (
  <img
    src={PageLoaderGif}
    alt=""
    style={{
      transform: "scale(5.5)",
    }}
  />
);

export const PageLoader = () => {
  return (
    <>
      <FlexRowWrapper
        style={{
          alignItems: "center",
          height: "99vh",
          justifyContent: "space-evenly",
          background: "rgba(5, 5, 5, .4)",
          backdropFilter: "blur(1.5px)",
        }}
      >
        {PageGif}
        {PageGif}
        {PageGif}
        {/* 
        {PageGif}
        {PageGif} */}
      </FlexRowWrapper>
    </>
  );
};
