import styled from "styled-components";

const StyledInput = styled.input`
  background-color: #1e2228;
  color: white;
  border-radius: 8px;
  padding: 12px 16px;
  border: 1px solid #ffffff;
  background-color: transparent;
  transition: border-color 0.3s, box-shadow 0.3s;
  width: 100%;
  height: 20px;
  position: relative;
  z-index: 10;
  pointer-events: auto;
  @media (max-width: 768px) {
    height: 10px;
  }

  ::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    border-color: #00aaff; /* or another color of your choice */
    box-shadow: 0 0 10px rgba(0, 170, 255, 0.5); /* subtle glow effect */
  }
`;

export default StyledInput;
